import axios from "../utils/request";

// *招聘列表
export let getJobList = (params) => axios.post("jobs/getJobs", params);

//*添加招聘
export let addJob = (params) => axios.post("jobs/addJob", params);

// *编辑招聘
export let updateJob = (params) => axios.post("jobs/updateJob", params);

// *删除招聘
export let deleteJob = (params) => axios.post("jobs/removeJob", params);
