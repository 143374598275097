<template>
  <div>
    <div>
      <el-button type="primary" @click="addJobBtn">添加招聘</el-button>
    </div>
    <!-- 列表 -->
    <el-table ref="mytable" :data="list">
      <el-table-column type="expand">
        <template slot-scope="props">
          <div class="flex-def flex-between" style="width: 850px;">
            <div style="width: 400px;">
              <p class="font18 mb10">岗位职责</p>
              <p v-html="props.row.statement" style="white-space: pre-wrap"></p>
            </div>
            <div style="width: 400px;">
              <p class="font18 mb10">岗位描述</p>
              <p v-html="props.row.describe" style="white-space: pre-wrap"></p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="职位名称" prop="title"></el-table-column>
      <el-table-column label="学历" prop="edu"></el-table-column>
      <el-table-column label="专业" prop="profession"></el-table-column>
      <el-table-column label="人数" prop="amount"></el-table-column>
      <el-table-column label="工作地点" prop="workplace"></el-table-column>
      <el-table-column label="发布时间" prop="createTime"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="item">
          <el-button @click="clickEditJob(item.row)">编辑</el-button>
          <el-button type="danger" @click="clickRemove(item.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="mt30">
      <el-pagination
        @size-change="sizechange"
        @current-change="pagechange"
        :current-page="curpage"
        :page-sizes="changepagesizes"
        :page-size="curpagesize"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
    </div>
    <!-- 新增弹框 -->
    <div class="dialog-box">
      <el-dialog :title="titleDialog" :visible.sync="showbox">
        <el-form
          :model="jobFrom"
          :rules="rules"
          ref="jobFrom"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="职位名称" prop="title">
            <el-input v-model="jobFrom.title"></el-input>
          </el-form-item>
          <el-form-item label="学历" prop="edu">
            <el-input v-model="jobFrom.edu"></el-input>
          </el-form-item>
          <el-form-item label="人数" prop="amount">
            <el-input-number
              v-model="jobFrom.amount"
              controls-position="right"
              :min="0"
              :step="1"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="专业" prop="profession">
            <el-input v-model="jobFrom.profession"></el-input>
          </el-form-item>
          <el-form-item label="工作地点" prop="workplace">
            <el-input v-model="jobFrom.workplace"></el-input>
          </el-form-item>
          <el-form-item label="发布时间" prop="createTime">
            <el-form-item prop="createTime">
              <el-date-picker
                v-model="jobFrom.createTime"
                type="date"
                placeholder="选择日期"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-form-item>
          <el-form-item label="岗位职责" prop="statement">
            <el-input
              type="textarea"
              v-model="jobFrom.statement"
              :autosize="{ minRows: 5, maxRows: 6 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="岗位描述" prop="describe">
            <el-input
              type="textarea"
              v-model="jobFrom.describe"
              :autosize="{ minRows: 5, maxRows: 6 }"
            ></el-input>
          </el-form-item>
        </el-form>
        <div>
          <el-button
            v-if="titleDialog == '添加招聘'"
            type="primary"
            @click="submitForm('jobFrom', 0)"
            >创建招聘</el-button
          >
          <el-button v-else type="danger" @click="submitForm('jobFrom', 1)"
            >更改招聘</el-button
          >
          <el-button @click="resetForm('jobFrom')">重置</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getJobList, addJob, updateJob, deleteJob } from "@/apis/join";
export default {
  components: {},
  props: {},
  data() {
    return {
      titleDialog: "",
      list: [],
      total: 0, //总条数
      changepagesizes: [5, 10, 15, 20, 25], //可以切换的条数数组
      curpage: 1, //当前页数
      curpagesize: 10, //当前选中的条数
      showbox: false, //新增弹框
      jobFrom: {},
      rules: {
        title: [{ required: true, message: "请输入职位名称", trigger: "blur" }],
        edu: [{ required: true, message: "请输入学历", trigger: "blur" }],
        amount: [{ required: true, message: "请输入人数", trigger: "blur" }],
        profession: [
          { required: true, message: "请输入专业", trigger: "blur" },
        ],
        workplace: [{ required: true, message: "请输入地点", trigger: "blur" }],
        createTime: [
          {
            required: true,
            message: "请选择日期",
            trigger: "blur",
          },
        ],
        describe: [
          { required: true, message: "请输入岗位描述", trigger: "blur" },
        ],
        statement: [
          { required: true, message: "请输入岗位职责", trigger: "blur" },
        ],
      },
    };
  },
  watch: {},
  computed: {},
  mounted() {
    this.getJobList();
  },
  methods: {
    //*获取招聘列表
    getJobList() {
      getJobList({ page: this.curpage, size: this.curpagesize }).then((res) => {
        if (res.data.code == 0) {
          this.list = res.data.data.news;
          this.total = res.data.data.count;
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    // *提交
    submitForm(formName, type) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (type == 1) {
            updateJob(this.jobFrom).then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  message: `修改成功`,
                  type: "success",
                });
                this.getJobList();
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "warning",
                });
              }
            });
          } else {
            addJob(this.jobFrom).then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  message: `添加成功`,
                  type: "success",
                });
                this.getJobList();
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "warning",
                });
              }
            });
          }
          this.showbox = false;

          this.resetForm(formName);
        } else {
          this.$message({
            message: "提交失败",
            type: "warning",
          });
          return false;
        }
      });
    },
    //点击切换每页显示的条数
    sizechange(newsize) {
      this.curpagesize = newsize; //先改变新的显示条数
      this.getJobList(); //请求新数据！
    },
    //点击跳转页数
    pagechange(newpage) {
      this.curpage = newpage; //先把当前页数切换到新的页数
      this.getJobList(); //请求新页的数据
    },
    // 添加招聘
    addJobBtn() {
      this.titleDialog = "添加招聘";
      this.showbox = true;
      this.jobFrom = {};
    },
    //重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // *编辑招聘
    clickEditJob(row) {
      this.titleDialog = "编辑招聘";
      this.showbox = true;
      this.jobFrom = row;
    },
    // *删除招聘二次确认
    clickRemove(row) {
      this.$confirm("此操作将永久删除该招聘, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteJob({ id: row.id }).then((res) => {
          if (res.data.code == 0) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getJobList();
          } else {
            this.$message({
              message: res.data.msg,
              type: "warning",
            });
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-box {
  /deep/.el-dialog {
    margin-top: 8vh !important;
  }
  /deep/.el-dialog__body {
    padding: 10px 20px 20px 20px;
  }
}
</style>
